import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet, isIPad13 } from "react-device-detect";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import downArrow from "../../../images/Homepage/down-arrow.svg";
import integration1 from "../../../images/Business/integration1.png";
import integration2 from "../../../images/Business/integration2.png";
import integration3 from "../../../images/Business/integration3.png";
import integration4 from "../../../images/Business/integration4.png";
import logo from "../../../images/header/clever-icon.png";
import bubble1 from "../../../images/Business/bubble1.png";
import bubble2 from "../../../images/Business/bubble2.png";
import cashdesk from "../../../images/Business/cashdesk.png";
import greenPhone from "../../../images/Business/green_phone.png";
import purplePhone from "../../../images/Business/purple_phone.png";
import greenPhoneMobile from "../../../images/Business/green_phone_mobile.png";
import purplePhoneMobile from "../../../images/Business/purple_phone_mobile.png";
import "./LoyaltySection.scss";

var lottie1 = null;

class LoyaltySection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitle1expanded: false,
      subtitle3expanded: false
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(
      [
        this.title,
        this.subtitle,
        this.title1,
        this.subtitle1,
        this.expandArrow1,
        this.bubble1,
        this.bubble2,
        this.cashdesk,
        this.title2,
        this.label3,
        this.title3,
        this.subtitle3,
        this.expandArrow3,
        this.button3,
        this.label5,
        this.title5,
        this.subtitle5,
        this.expandArrow5,
        this.button5,
        this.title4,
        this.subtitle4,
        this.integr1,
        this.integr2,
        this.integr3,
        this.integr4,
        this.logo
      ],
      {
        y: 50,
        opacity: 0
      }
    );
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.subtitle, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    //Card 1
    gsap.to(this.title1, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.subtitle1, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.expandArrow1, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.cashdesk, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.4,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.bubble1, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.bubble2, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.8,
      duration: 1,
      ease: "power3"
    });

    //Card 2
    gsap.to(this.title2, {
      scrollTrigger: {
        trigger: this.title1,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    //Card 3
    gsap.set(this.greenImage, {
      opacity: 0,
      y: 100
    });

    gsap.to(this.greenImage, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1.0,
      ease: "power3"
    });

    gsap.to(this.label3, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.title3, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.subtitle3, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.expandArrow3, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.7,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.button3, {
      scrollTrigger: {
        trigger: this.title3,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.7,
      duration: 1,
      ease: "power3"
    });

     //Card 5
     gsap.set(this.purpleImage, {
      opacity: 0,
      y: 100
    });

    gsap.to(this.purpleImage, {
      scrollTrigger: {
        trigger: this.title5,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1.0,
      ease: "power3"
    });

    gsap.to(this.label5, {
      scrollTrigger: {
        trigger: this.title5,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.7,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.title5, {
      scrollTrigger: {
        trigger: this.title5,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.8,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.subtitle5, {
      scrollTrigger: {
        trigger: this.title5,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.9,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.expandArrow5, {
      scrollTrigger: {
        trigger: this.title5,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.button5, {
      scrollTrigger: {
        trigger: this.title5,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 1,
      duration: 1,
      ease: "power3"
    });

    //card 4

    gsap.to(this.title4, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.subtitle4, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.integr1, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.integr2, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.7,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.logo, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 0.9,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.integr3, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 1.1,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.integr4, {
      scrollTrigger: {
        trigger: this.title4,
        start: "top 70%"
      },
      opacity: 1,
      y: 0,
      delay: 1.3,
      duration: 1,
      ease: "power3"
    });
  }

  expandSubtitle = (item) => {
    if (item == 1) {
      if (!this.state.subtitle1expanded) {
        gsap.to(this.subtitle1, {
          opacity: 1,
          height: "auto",
          overflow: "visible",
          delay: 0,
          duration: 0.5,
          ease: "power3"
        });
        if (isTablet || isIPad13) {
          gsap.to(this.images, {
            opacity: 0,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }
      } else {
        gsap.to(this.subtitle1, {
          opacity: 0,
          height: 0,
          overflow: "hidden",
          delay: 0,
          duration: 0.5,
          ease: "power3"
        });
        if (isTablet || isIPad13) {
          gsap.to(this.images, {
            opacity: 1,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }
      }
      this.setState({ subtitle1expanded: !this.state.subtitle1expanded });
    } else if (item == 3) {
      if (!this.state.subtitle3expanded) {
        gsap.to(this.subtitle3, {
          opacity: 1,
          height: "auto",
          delay: 0,
          duration: 0.5,
          ease: "power3"
        });

        if (isTablet) {
          gsap.to(this.label3, {
            opacity: 0,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }
      } else {
        gsap.to(this.subtitle3, {
          opacity: 1,
          height: 0,
          delay: 0,
          duration: 0.5,
          ease: "power3"
        });
        if (isTablet) {
          gsap.to(this.label3, {
            opacity: 1,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }
      }
      this.setState({ subtitle3expanded: !this.state.subtitle3expanded });
    }
    else if (item == 5) {
      if (!this.state.subtitle5expanded) {
        gsap.to(this.subtitle5, {
          opacity: 1,
          height: "auto",
          delay: 0,
          duration: 0.5,
          ease: "power3"
        });

        if (isTablet) {
          gsap.to(this.label5, {
            opacity: 0,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }
      } else {
        gsap.to(this.subtitle5, {
          opacity: 1,
          height: 0,
          delay: 0,
          duration: 0.5,
          ease: "power3"
        });
        if (isTablet) {
          gsap.to(this.label5, {
            opacity: 1,
            delay: 0,
            duration: 0.5,
            ease: "power3"
          });
        }
      }
      this.setState({ subtitle5expanded: !this.state.subtitle5expanded });
    }
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="loyaltySection">
        <div className="loyaltySection_cont">
          <div className="loyaltySection_cont_textCont">
            <h2 ref={(ref) => (this.title = ref)} className="testimonials_cont_topRow_textCont_title">
              {intl.formatMessage({ id: "Business.Loyalty.Title" })}
            </h2>
            <p ref={(ref) => (this.subtitle = ref)} className="testimonials_cont_topRow_textCont_description">
              {intl.formatMessage({ id: "Business.Loyalty.Subtitle" })}
            </p>
          </div>
          <div className="loyaltySection_cont_boxesCont">
            <div className="loyaltySection_cont_boxesCont_row">
              <div className="loyaltySection_cont_boxesCont_row_loyaltyBox1">
                <h2 ref={(ref) => (this.title1 = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox1_title">
                  {intl.formatMessage({ id: "Business.Loyalty.Items.Item1.Title-part-1" })}{" "}
                  <strong>{intl.formatMessage({ id: "Business.Loyalty.Items.Item1.Title-part-2" })}</strong>
                </h2>
                <div
                  ref={(ref) => (this.subtitle1 = ref)}
                  className="loyaltySection_cont_boxesCont_row_loyaltyBox1_subtitleWrapper">
                  <p className="loyaltySection_cont_boxesCont_row_loyaltyBox1_subtitle">
                    {intl.formatMessage({ id: "Business.Loyalty.Items.Item1.Subtitle" })}
                  </p>
                </div>
                <img
                  ref={(ref) => (this.expandArrow1 = ref)}
                  className="loyaltySection_cont_boxesCont_row_loyaltyBox1_expandArrow"
                  style={{ transform: this.state.subtitle1expanded ? "rotate(180deg)" : null }}
                  src={downArrow}
                  onClick={() => this.expandSubtitle(1)}
                />
                <div ref={(ref) => (this.images = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox1_images">
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox1_images_bubbles">
                    <img
                      ref={(ref) => (this.bubble1 = ref)}
                      src={bubble1}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox1_images_bubbles_bubble1"
                    />
                    <img
                      ref={(ref) => (this.bubble2 = ref)}
                      src={bubble2}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox1_images_bubbles_bubble2"
                    />
                  </div>
                  <img
                    ref={(ref) => (this.cashdesk = ref)}
                    src={cashdesk}
                    className="loyaltySection_cont_boxesCont_row_loyaltyBox1_images_cashdesk"
                  />
                </div>
              </div>
              <div className="loyaltySection_cont_boxesCont_row_loyaltyBox2">
                <h2 ref={(ref) => (this.title2 = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox2_title">
                  {intl.formatMessage({ id: "Business.Loyalty.Items.Item2.Title-part-1" })}{" "}
                  <strong>{intl.formatMessage({ id: "Business.Loyalty.Items.Item2.Title-part-2" })}</strong>
                </h2>
              </div>
            </div>
            <div className="loyaltySection_cont_boxesCont_row">
              <div className="loyaltySection_cont_boxesCont_row_loyaltyBox3">
                <div className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont">
                  <span
                    ref={(ref) => (this.label3 = ref)}
                    className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_label">
                    {intl.formatMessage({ id: "Business.Loyalty.Items.Item3.Label" })}
                  </span>
                  <h2 ref={(ref) => (this.title3 = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_title">
                    {intl.formatMessage({ id: "Business.Loyalty.Items.Item3.Title" })}
                  </h2>
                  <div
                    ref={(ref) => (this.subtitle3 = ref)}
                    className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_subtitleWrapper">
                    <p className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_description">
                      {intl.formatMessage({ id: "Business.Loyalty.Items.Item3.Subtitle" })}
                    </p>
                  </div>
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_buttonCont">
                    <OutboundLink href="https://merchant.helloclever.co/" target="_blank">
                      <div
                        ref={(ref) => (this.button3 = ref)}
                        className="button button-transparent loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_button">
                        <p>{intl.formatMessage({ id: "Business.Loyalty.Items.Item3.Button" })}</p>
                      </div>
                    </OutboundLink>
                    <img
                      ref={(ref) => (this.expandArrow3 = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox3_leftCont_expandArrow"
                      style={{ transform: this.state.subtitle3expanded ? "rotate(180deg)" : null }}
                      src={downArrow}
                      onClick={() => this.expandSubtitle(3)}
                    />
                  </div>
                </div>
                <div className="loyaltySection_cont_boxesCont_row_loyaltyBox3_rightCont">
                  {(isBrowser || isTablet) && (
                    <img
                      ref={(ref) => (this.greenImage= ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox3_rightCont_image"
                      src={greenPhone}
                      alt={"aircrex clever mockup"}
                    />
                  )}
                  {isMobile && !isTablet && (
                    <div className="loyaltySection_cont_boxesCont_row_loyaltyBox3_rightCont_imageMobile">
                      <img
                        alt={"aircrex clever mockup"}
                        className="loyaltySection_cont_boxesCont_row_loyaltyBox3_rightCont_imageMobile_image"
                        src={greenPhoneMobile}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="loyaltySection_cont_boxesCont_row_loyaltyBox5">
                <div className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont">
                  <span
                    ref={(ref) => (this.label5 = ref)}
                    className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_label">
                    {intl.formatMessage({ id: "Business.Loyalty.Items.Item5.Label" })}
                  </span>
                  <h2 ref={(ref) => (this.title5 = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_title">
                    {intl.formatMessage({ id: "Business.Loyalty.Items.Item5.Title" })}
                  </h2>
                  <div
                    ref={(ref) => (this.subtitle5 = ref)}
                    className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_subtitleWrapper">
                    <p className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_description">
                      {intl.formatMessage({ id: "Business.Loyalty.Items.Item5.Subtitle" })}
                    </p>
                  </div>
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_buttonCont">
                    <OutboundLink href="https://merchant.helloclever.co/" target="_blank">
                      <div
                        ref={(ref) => (this.button5 = ref)}
                        className="button button-transparent loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_button">
                        <p>{intl.formatMessage({ id: "Business.Loyalty.Items.Item5.Button" })}</p>
                      </div>
                    </OutboundLink>
                    <img
                      ref={(ref) => (this.expandArrow5 = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox5_leftCont_expandArrow"
                      style={{ transform: this.state.subtitle5expanded ? "rotate(180deg)" : null }}
                      src={downArrow}
                      onClick={() => this.expandSubtitle(5)}
                    />
                  </div>
                </div>
                <div className="loyaltySection_cont_boxesCont_row_loyaltyBox5_rightCont">
                  {(isBrowser || isTablet) && (
                    <img
                      ref={(ref) => (this.purpleImage= ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox5_rightCont_image"
                      src={purplePhone}
                      alt={"aircrex clever mockup"}
                    />
                  )}
                  {isMobile && !isTablet && (
                    <div className="loyaltySection_cont_boxesCont_row_loyaltyBox5_rightCont_imageMobile">
                      <img
                        alt={"aircrex clever mockup"}
                        className="loyaltySection_cont_boxesCont_row_loyaltyBox5_rightCont_imageMobile_image"
                        src={purplePhoneMobile}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="loyaltySection_cont_boxesCont_row">
              <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4">
                <h2 ref={(ref) => (this.title4 = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox4_title">
                  {intl.formatMessage({ id: "Business.Loyalty.Items.Item4.Title" })}{" "}
                </h2>
                <p ref={(ref) => (this.subtitle4 = ref)} className="loyaltySection_cont_boxesCont_row_loyaltyBox1_description">
                  {intl.formatMessage({ id: "Business.Loyalty.Items.Item4.Subtitle" })}{" "}
                  <OutboundLink href="https://docs.cleverpay.me/api-reference/" target="_blank">
                    {intl.formatMessage({ id: "Business.Loyalty.Items.Item4.Link" })}
                  </OutboundLink>
                </p>
                <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations">
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column">
                    <div
                      ref={(ref) => (this.integr1 = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item">
                      <OutboundLink
                        href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=43234&filter_license=0"
                        target="_blank">
                        <>
                          <img
                            src={integration1}
                            className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_image"
                          />
                          <span className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_name">
                            Opencart
                          </span>
                        </>
                      </OutboundLink>
                    </div>
                  </div>
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column">
                    <div
                      ref={(ref) => (this.integr2 = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item">
                      <OutboundLink href="https://wordpress.org/plugins/cleverpay-gateway/" target="_blank">
                        <>
                          <img
                            src={integration2}
                            className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_image"
                          />
                          <span className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_name">
                            Woocommerce
                          </span>
                        </>
                      </OutboundLink>
                    </div>
                  </div>
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column">
                    <div
                      ref={(ref) => (this.logo = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_itemLogo">
                      <img
                        src={logo}
                        className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_itemLogo_image"
                      />
                    </div>
                  </div>
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column">
                    <div
                      ref={(ref) => (this.integr3 = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item">
                      <OutboundLink href="https://apps.shopify.com/cleverpay" target="_blank">
                        <>
                          <img
                            src={integration3}
                            className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_image"
                          />
                          <span className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_name">
                            Shopify
                          </span>
                        </>
                      </OutboundLink>
                    </div>
                  </div>
                  <div className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column">
                    <div
                      ref={(ref) => (this.integr4 = ref)}
                      className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item">
                      <OutboundLink href="https://marketplace.magento.com/cleverpay-payment-gateway.html" target="_blank">
                        <>
                          <img
                            src={integration4}
                            className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_image"
                          />
                          <span className="loyaltySection_cont_boxesCont_row_loyaltyBox4_integrations_column_item_name">
                            Magento
                          </span>
                        </>
                      </OutboundLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(LoyaltySection);
