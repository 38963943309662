import * as React from "react";
import Layout from "../components/layout";
import Hero from "../containers/HomePage/Hero/Hero";
import ColorfulCTAComponent from "../containers/Common/ColorfulCTAComponent/ColorfulCTAComponent";
import { StickyContainer } from "react-sticky";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import SubpageHero from "../containers/Common/SubpageHero/SubpageHero";
import image from "../images/business/heroImg.webp";
import LoyaltySection from "../containers/Business/LoyaltySection/LoyaltySection";

// markup
const Business = () => {
  const intl = useIntl();
  return (
    <StickyContainer>
      <Layout>
        <SubpageHero
          title={
            intl.formatMessage({ id: "Business.Hero.Title-part-1" }) +
            "\n" +
            intl.formatMessage({ id: "Business.Hero.Title-part-2" })
          }
          subtitle={intl.formatMessage({ id: "Business.Hero.Subtitle" })}
          image={image}
        />
        <LoyaltySection />
        <ColorfulCTAComponent
          title={intl.formatMessage({ id: "Business.Signup.Title" }) + "\n" + intl.formatMessage({ id: "Business.Signup.Title-2" })}
          subtitle={intl.formatMessage({ id: "Business.Signup.Subtitle" })}
          button={intl.formatMessage({ id: "Business.Signup.Button" })}
          link={"https://helloclever.typeform.com/enquiry"}
        />
      </Layout>
    </StickyContainer>
  );
};

export default Business;
